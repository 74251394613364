import "./index.css";
import { useState } from "react";
import { Flex, Skeleton } from "antd";

const values = [30, 40, 50, 60];
const getRandomHeight = () => `${values[Math.floor(Math.random() * values.length)]}vh`;

const ImageWithLoader = ({ style, ...props }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Flex
          className="vfra-image-loader-container"
          justify="center"
          align="center"
          style={{ height: getRandomHeight() }}>
          <Skeleton.Node active={true} />
        </Flex>
      )}
      <img {...props} onLoad={handleImageLoaded} style={{ ...style, display: loading ? "none" : "block" }} alt="" />
    </>
  );
};

export default ImageWithLoader;
