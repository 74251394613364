import "./index.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../components/contexts/AuthContext";
import { Flex, Button, Modal, Tabs } from "antd";
import { getPosts, deletePost, getPostsByReaction, removePostReaction } from "../../../services/supabaseClient";
import useWindowSize from "../../../components/hooks/useWindowSize";
import CreatePost from "../../../components/common/CreatePost";
import ListPost from "../../../components/common/ListPost";

const Community = () => {
  const { user } = useAuth();
  const { userId } = useParams();
  const { isMobileView } = useWindowSize();

  const isYourself = user && user.user_id === userId;
  const [showCreatePost, setShowCreatePost] = useState(false);

  const [reloadPosts, setReloadPosts] = useState(false);

  const handleCreatePost = () => {
    setShowCreatePost(false);
    setReloadPosts((prev) => !prev);
  };

  let menu = [
    {
      key: "published",
      label: `Published`,
      children: (
        <ListPost
          type="published"
          title="List published"
          emptyTitle="Publish post empty"
          emptyContent="Posts appear here after you publish and will be visible to your community"
          reload={reloadPosts}
          fetchApi={getPosts}
          deleteApi={deletePost}
          successDeleteMessage="Post was deleted successfully!"
        />
      ),
    },
  ];

  if (isYourself) {
    menu = [
      ...menu,
      {
        key: "saved",
        label: `Saved`,
        children: (
          <ListPost
            type="saved"
            title="List saved"
            emptyTitle="Save post empty"
            emptyContent="Posts appear here after you save post in the explore"
            fetchApi={getPostsByReaction}
            deleteApi={removePostReaction}
            successDeleteMessage="Post was deleted from list save!"
          />
        ),
      },
      {
        key: "loved",
        label: `Loved`,
        children: (
          <ListPost
            type="loved"
            title="List publish"
            emptyTitle="Love post empty"
            emptyContent="Posts appear here after you love post in the explore"
            fetchApi={getPostsByReaction}
            deleteApi={removePostReaction}
            successDeleteMessage="Post was deleted from list love!"
          />
        ),
      },
    ];
  }

  return (
    <div className="vfra-community-container">
      {isYourself && !showCreatePost && (
        <Flex justify="center">
          <Button onClick={() => setShowCreatePost(true)} shape="round">
            Create a new post
          </Button>
        </Flex>
      )}

      <Tabs centered defaultActiveKey="published" items={menu} />

      <Modal
        width={isMobileView ? "100%" : "70%"}
        centered
        open={showCreatePost}
        closable={false}
        footer={null}
        getContainer={false}>
        <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
      </Modal>
    </div>
  );
};
export default Community;
