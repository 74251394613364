import { useState, useEffect, useCallback } from "react";
import { Col, Row, Flex, message, Spin } from "antd";
import { classifyDataByName } from "../../../utils/service";
import { getAllBrandsLite } from "../../../services/supabaseClient";
import { setItemWithExpiry, getItemWithExpiry, localStorage_fields, expires_in } from "../../../utils/localstorage";
import useWindowSize from "../../../components/hooks/useWindowSize";
import BrandItem from "../BrandItem";

const PopularBrands = () => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  const fetchDataPopularBrands = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await getAllBrandsLite();
      if (error) {
        message.error(error.message);
      } else {
        const converted = classifyDataByName(data, "brand_name");
        setBrands(converted);
        setItemWithExpiry(localStorage_fields.groupBrands, converted, expires_in.week);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const brandsData = getItemWithExpiry(localStorage_fields.groupBrands);

    if (brandsData) {
      setBrands(brandsData);
    } else {
      fetchDataPopularBrands();
    }
  }, [fetchDataPopularBrands]);

  return (
    <>
      {loading && (
        <Flex justify="center" style={{ marginTop: "20%" }}>
          <Spin size="large" />
        </Flex>
      )}

      {!loading && brands.length === 0 && (
        <Flex justify="center" style={{ marginTop: "2rem" }}>
          <p style={{ color: "rgb(100 100 100)" }}>No brand found!</p>
        </Flex>
      )}

      {brands.length > 0 && (
        <Row gutter={[6, 6]} justify="center">
          {brands.map((group) => {
            if (group.designers.length === 0) {
              return null;
            } else {
              return (
                <Col key={group.alphaCode} span={!isMobileView ? 6 : 24}>
                  <h3
                    style={{
                      margin: 0,
                      marginBottom: "0.75rem",
                      marginLeft: "0.75rem",
                      lineHeight: "1.4",
                      fontSize: "1.5rem",
                    }}>
                    {group.alphaCode}
                  </h3>
                  {group.designers.map((designer) => {
                    return <BrandItem key={designer.id} designer={designer} />;
                  })}
                </Col>
              );
            }
          })}
        </Row>
      )}
    </>
  );
};

export default PopularBrands;
