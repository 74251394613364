import "./index.css";
import { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { optionDataVotes, initDataVotes } from "../../../../../utils/data";
import useWindowSize from "../../../../../components/hooks/useWindowSize";
import Vote from "./Vote";
import GenderVote from "./Gender";
import WeatherVote from "./Weather";
import SeasonVote from "./Season";

const getUserVote = (field, data) => {
  if (!data || !data[field]) return null;
  return Object.keys(data[field])[0];
};

const Votes = ({ dataVotes, dataVotesByUser }) => {
  const { isMobileView } = useWindowSize();

  const [seasonsData, setSeasonsData] = useState(null);
  const [weathersData, setWeathersData] = useState(null);
  const [gendersData, setGendersData] = useState(null);
  const [ortherVotesData, setOrtherVotesData] = useState(null);

  const [userVotesData, setUserVotesData] = useState(null);

  const setInitDataVotes = (data) => {
    const initData = data ? data : initDataVotes;
    const { seasons, weathers, genders, longevity, sillage, price_value } = initData;

    setSeasonsData(seasons);
    setWeathersData(weathers);
    setGendersData(genders);

    const initOrtherVotes = {
      longevity,
      sillage,
      price_value,
    };

    setOrtherVotesData(initOrtherVotes);
  };

  const setInitDataVotesByUser = (data) => {
    const seasons_vote = data?.seasons ?? null;
    const weathers_vote = data?.weathers ?? null;
    const genders_vote = getUserVote("genders", data);

    const userVotes = {
      seasons: seasons_vote,
      weathers: weathers_vote,
      genders: genders_vote,
      longevity: data?.longevity ?? null,
      sillage: data?.sillage ?? null,
      price_value: data?.price_value ?? null,
    };

    setUserVotesData(userVotes);
  };

  useEffect(() => {
    setInitDataVotes(dataVotes);
    setInitDataVotesByUser(dataVotesByUser);
  }, [dataVotes, dataVotesByUser]);

  return (
    <>
      <Divider style={{ fontSize: "0.9rem", fontWeight: "bold", marginTop: !isMobileView ? "0" : "6px" }}>
        Votes
      </Divider>

      <Row gutter={[0, !isMobileView ? 16 : 24]}>
        <Col span={24}>
          <Row gutter={isMobileView ? [0, 24] : [0, 0]}>
            <Col span={!isMobileView ? 16 : 24}>
              <SeasonVote data={seasonsData} userVote={userVotesData} />
            </Col>
            <Col span={!isMobileView ? 8 : 24}>
              <WeatherVote data={weathersData} userVote={userVotesData} />
            </Col>
          </Row>
        </Col>

        {optionDataVotes.map((vote) => {
          return (
            <Col key={vote.name} span={8}>
              <Vote data={ortherVotesData} vote={vote} userVote={userVotesData} />
            </Col>
          );
        })}

        <Col span={24} style={{ marginTop: "12px" }}>
          <GenderVote data={gendersData} userVote={userVotesData} />
        </Col>
      </Row>
    </>
  );
};

export default Votes;
