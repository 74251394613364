import "./index.css";
import { useState, useEffect } from "react";
import SearchPerfumes from "./SearchPerfumes";
import { useSearch } from "../../components/contexts/SearchContext";

// import PopularPerfumes from "./PopularPerfumes";
// import ExplorePerfumes from "./ExplorePerfumes";
// import NewfeedPerfumes from "./NewfeedPerfumes";
import ScentubExplore from "./ScentubExplore";

const Perfumes = () => {
  const [searchMode, setSearchMode] = useState(false);
  const { searchValue } = useSearch();

  useEffect(() => {
    if (!searchValue) {
      setSearchMode(false);
    } else {
      setSearchMode(true);
    }
  }, [searchValue]);

  return (
    <>
      {/* layout 1 */}
      {/* {!searchMode && <PopularPerfumes />} */}

      {/* layout 2 */}
      {/* {!searchMode && <ExplorePerfumes />} */}

      {/* newfeed layout */}
      {/* {!searchMode && <NewfeedPerfumes />} */}

      {/* newfeed layout */}
      {!searchMode && <ScentubExplore />}

      {searchMode && <SearchPerfumes searchValue={searchValue} />}
    </>
  );
};

export default Perfumes;
