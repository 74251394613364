import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import "./antd-overrides.css";
import { useAuth } from "./components/contexts/AuthContext";
import { pathData } from "./utils/routes";
import useViewportHeight from "./components/hooks/useViewportHeight";
import MainLayout from "./components/layouts/MainLayout";
import BasicLayout from "./components/layouts/BasicLayout";
import NoneFooterLayout from "./components/layouts/NoneFooterLayout";
import Home from "./pages/Home";
import PostDetail from "./components/common/Posts/PostDetail";
import SearchPostsDetail from "./pages/SearchPostsDetail";
import Perfume from "./pages/Perfumes/Perfume";
import BrandDetails from "./pages/Brands/BrandDetails";
import AllRelatePost from "./pages/Perfumes/Perfume/AllRelatedPost";
import LoginModal from "./components/common/LoginModal";
import Profile from "./pages/Profile";
import MultiSearch from "./pages/MultiSearch";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  const { showLoginModal, closeLoginModal } = useAuth();

  // Invoke the custom hook to set the viewport height dynamically
  useViewportHeight();

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.includes(pathData.explore) ||
      location.pathname.includes(pathData.searchDetail) ||
      location.pathname.includes(pathData.postDetail)
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [location]);

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Perfume Reviews & Scented Stories from Around the World</title>

                  <meta
                    name="description"
                    content="Scentub - the social platform for perfume reviews, fragrance collections, and scent inspiration. Find trending perfumes, share your latest discoveries, and explore recommendations from fragrance lovers worldwide. Join us and turn your love for scents into a shared experience."
                  />
                </Helmet>
                <MultiSearch />
              </>
            }></Route>

          <Route
            path={`${pathData.home}/:tabId?`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Perfume Reviews & Scented Stories from Around the World</title>

                  <meta
                    name="description"
                    content="Scentub - the social platform for perfume reviews, fragrance collections, and scent inspiration. Find trending perfumes, share your latest discoveries, and explore recommendations from fragrance lovers worldwide. Join us and turn your love for scents into a shared experience."
                  />
                </Helmet>
                <MultiSearch />
              </>
            }></Route>

          <Route
            path={`${pathData.perfume}/:perfumeId`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Perfume details</title>
                  <meta name="description" content="Find out about the perfume you are looking for" />
                </Helmet>
                <Perfume />
              </>
            }
          />
          <Route
            path={`${pathData.perfume}/:perfumeId/relate-post`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - All posts</title>
                </Helmet>
                <AllRelatePost />
              </>
            }
          />
          <Route
            path={`${pathData.brand}/:brandId`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Designer</title>
                  <meta name="description" content="Top perfume designers" />
                </Helmet>
                <BrandDetails />
              </>
            }
          />
          <Route
            path={`${pathData.profile}/:userId`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Profile</title>
                </Helmet>
                <Profile />
              </>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route element={<NoneFooterLayout />}>
          <Route
            path={`${pathData.explore}`}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Perfume Reviews & Scented Stories from Around the World</title>

                  <meta
                    name="description"
                    content="Scentub - the social platform for perfume reviews, fragrance collections, and scent inspiration. Find trending perfumes, share your latest discoveries, and explore recommendations from fragrance lovers worldwide. Join us and turn your love for scents into a shared experience."
                  />
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path={pathData.home}
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Perfume Reviews & Scented Stories from Around the World</title>

                  <meta
                    name="description"
                    content="Scentub - the social platform for perfume reviews, fragrance collections, and scent inspiration. Find trending perfumes, share your latest discoveries, and explore recommendations from fragrance lovers worldwide. Join us and turn your love for scents into a shared experience."
                  />
                </Helmet>
                <Home />
              </>
            }></Route>
          <Route path={`${pathData.postDetail}/:postId`} element={<PostDetail />}></Route>
          <Route path={`${pathData.searchDetail}`} element={<SearchPostsDetail />}></Route>
        </Route>

        <Route element={<BasicLayout />}>
          <Route
            path="/terms"
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Terms of Sevices</title>
                  <meta name="description" content="Terms of Sevices" />
                </Helmet>
                <Terms />
              </>
            }
          />
          <Route
            path="/policy"
            element={
              <>
                <Helmet>
                  {window.location.hostname !== "scentub.com" && <meta name="robots" content="noindex" />}
                  <title>Scentub - Privacy Policies</title>
                  <meta name="description" content="Privacy Policies" />
                </Helmet>
                <Policy />
              </>
            }
          />
        </Route>
      </Routes>
      <LoginModal visible={showLoginModal} onCancel={closeLoginModal} />
    </>
  );
}

export default App;
