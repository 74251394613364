import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Divider, List, message, Skeleton, Flex, Button } from "antd";
import { getCommentsByTopic } from "../../../../services/supabaseClient";
import {
  colors,
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  DELETE_REACTION_ACTION,
  convertComment,
} from "../../../../utils/service";
import Comment from "./Comment";
import CommentArea from "./CommentArea";

const PAGE_SIZE = 5;

const Comments = () => {
  const { perfumeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(true);
  const [dataComments, setDataComments] = useState([]);

  const fetchDataComments = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime === 0) {
          setLoading(true);
        } else {
          setIsLoadmore(true);
        }

        const { data, error } = await getCommentsByTopic(perfumeId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            setShowLoadmore(true);

            const convertedData = data.map((e) => {
              return convertComment(e);
            });

            setDataComments((prev) => {
              if (startTime !== 0) {
                return [...prev, ...convertedData];
              }

              return convertedData;
            });
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime === 0) {
          setLoading(false);
        } else {
          setIsLoadmore(false);
        }
      }
    },
    [perfumeId]
  );

  useEffect(() => {
    fetchDataComments();
  }, [perfumeId, fetchDataComments]);

  const handleLoadMore = () => {
    const startTime = dataComments.length > 0 ? dataComments[dataComments.length - 1].created_at : 0;
    fetchDataComments(startTime);
  };

  const handleAddComment = (newComments) => {
    const convertedNewComments = convertComment(newComments);
    setDataComments((prev) => [convertedNewComments, ...prev]);
  };

  const handleDeleteComment = (id) => {
    const dataAfterDelete = dataComments.filter((c) => c.id !== id);
    setDataComments(dataAfterDelete);
  };

  const handleChangeCommentReaction = (commentId, reactionType, action) => {
    for (let i = 0; i < dataComments.length; i++) {
      if (dataComments[i].id === commentId) {
        if (reactionType === HEART_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].hearts_count++;
            dataComments[i].active_for_user_heart = true;
          } else if (action === DELETE_REACTION_ACTION) {
            dataComments[i].hearts_count--;
            dataComments[i].active_for_user_heart = false;
          }
        } else if (reactionType === LIKE_REACTION) {
          if (action === ADD_REACTION_ACTION) {
            dataComments[i].likes_count++;
            dataComments[i].active_for_user_like = true;
          } else if (action === DELETE_REACTION_ACTION) {
            dataComments[i].likes_count--;
            dataComments[i].active_for_user_like = false;
          }
        }
      }
    }

    setDataComments([...dataComments]);
  };

  return (
    <>
      <Divider style={{ fontWeight: "bold", fontSize: "0.9rem" }}>Reviews</Divider>
      <CommentArea totalComment={dataComments.length} onAddComment={handleAddComment} />
      <Divider style={{ margin: "1rem 0" }} />

      {loading && (
        <div>
          <Skeleton active avatar></Skeleton>
        </div>
      )}

      {!loading && dataComments.length > 0 && (
        <List
          itemLayout="vertical"
          size="large"
          className="vfra-comments"
          dataSource={dataComments}
          renderItem={(item) => (
            <Comment
              key={item.id}
              item={item}
              onDeleteComment={handleDeleteComment}
              onChangeCommentReaction={handleChangeCommentReaction}
            />
          )}
        />
      )}

      {!loading && showLoadmore && (
        <Flex justify="center">
          <Button
            style={{ fontSize: "1rem", color: colors.mainThemeColor, marginTop: "12px" }}
            size="small"
            shape="round"
            onClick={handleLoadMore}
            loading={isLoadmore}
            iconPosition="end">
            More comments
          </Button>
        </Flex>
      )}
    </>
  );
};

export default Comments;
