import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex, Card, Badge, Rate } from "antd";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const cardStyle = { width: "100%", border: "1px solid #E2E2E2" };

const Perfume = ({ item }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      style={cardStyle}
      bordered={false}
      onClick={() => navigate(`/perfume/${item.pid}`)}
      styles={{ body: { padding: isMobileView ? "6px" : "0.75rem" } }}>
      <Flex justify="start" align="center">
        <img alt={item.perfume_name} src={item.image_url} style={{ marginRight: "0.75rem", width: "4rem" }} />
        <Flex vertical={isMobileView} justify="space-between" style={{ width: "100%" }}>
          <span style={{ fontWeight: "700", marginBottom: isMobileView ? "12px" : "0px", fontSize: "0.8125rem" }}>
            {item.perfume_name ?? item.name}
          </span>

          <Flex align="center" gap={6}>
            <Badge
              count={item.rating}
              style={{
                fontSize: "0.8125rem",
                backgroundColor: "rgb(229 229 229 / 37%)",
                color: "rgba(0, 0, 0, 0.88)",
                fontWeight: "600",
              }}
            />
            <Rate disabled allowHalf defaultValue={item.rating} style={{ fontSize: "0.8rem" }} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Perfume;
