import "./index.css";
import { useRef, useState, useCallback, useEffect } from "react";
import { Button, Flex, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getNewestComments } from "../../../../services/supabaseClient";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import Comment from "./Comment";
import DividerTitle from "../DividerTitle";
const PAGE_SIZE = 10;

const TopComments = () => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { error, data } = await getNewestComments(PAGE_SIZE);

      if (error) {
        message.error(error.message);
      } else {
        setComments(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // sroll handling
  const containerRef = useRef(null);
  const [isScrolledToStart, setIsScrolledToStart] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const maxScrollLeft = isMobileView ? 20 : 100;
  const stepScroll = isMobileView ? 250 : 400;

  const handleScroll = () => {
    const container = containerRef.current;
    const isAtStart = container.scrollLeft > maxScrollLeft;
    const isAtEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth;

    setIsScrolledToStart(isAtStart);
    setIsScrolledToEnd(isAtEnd);
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -stepScroll, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: stepScroll, behavior: "smooth" });
  };

  const btnSize = isMobileView ? "small" : "middle";

  return (
    <>
      {!loading && (
        <Flex vertical gap={0} className="vfra-top-comments-parent-container">
          <DividerTitle title="Featured comments" />

          {isScrolledToStart && (
            <Button
              size={btnSize}
              className="vfra-top-reviewers-prev-btn"
              shape="circle"
              onClick={scrollLeft}
              icon={<LeftOutlined />}
            />
          )}

          <Flex
            ref={containerRef}
            onScroll={handleScroll}
            justify="start"
            className={`vfra-top-comments-container ${isScrolledToStart ? "vfra-top-comments-container-before" : ""}`}>
            {comments.map((p, index) => {
              return <Comment index={index} key={index} data={p} />;
            })}
          </Flex>

          {!isScrolledToEnd && (
            <Button
              size={btnSize}
              className="vfra-top-reviewers-next-btn"
              shape="circle"
              onClick={scrollRight}
              icon={<RightOutlined />}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default TopComments;
