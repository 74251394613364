import "./index.css";
import { useState, useEffect } from "react";
import { useSearch } from "../../components/contexts/SearchContext";
import PopularBrands from "./PopularBrands";
import SearchBrands from "./SearchBrands";

const Brands = () => {
  const [searchMode, setSearchMode] = useState(false);
  const { searchValue } = useSearch();

  useEffect(() => {
    if (!searchValue) {
      setSearchMode(false);
    } else {
      setSearchMode(true);
    }
  }, [searchValue]);

  return (
    <>
      {!searchMode && <PopularBrands />}
      {searchMode && <SearchBrands searchValue={searchValue} />}
    </>
  );
};

export default Brands;
