import "./index.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Flex, Popover, Button, Row, Col, Drawer, Modal, Input, Divider } from "antd";
import {
  UserOutlined,
  PlusOutlined,
  MenuOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
  // ClockCircleOutlined,
  LogoutOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { useSearch } from "../../../contexts/SearchContext";
import { localStorage_fields } from "../../../../utils/localstorage";
import { pathData, pathMenus } from "../../../../utils/routes";
import useWindowSize from "../../../hooks/useWindowSize";
import CreatePost from "../../../common/CreatePost";
import logoWeb from "../../../../assets/logo-web.png";
import logoMobile from "../../../../assets/logo-mobile.png";
import MultiSearch from "../../../../pages/MultiSearch";
const { Search } = Input;

const getMenuPath = (pathname) => {
  if (pathname === "/" || pathname.includes("/home"))
    return {
      currentPage: pathData.home,
      currentMenu: "Scentub",
    };

  const temp = pathMenus.find((e) => pathname.includes(e.path));
  return {
    currentPage: temp ? temp.path : "",
    currentMenu: temp ? temp.label : "",
  };
};

// const historiesSearchData = Array.from({ length: 10 }).map((_, i) => `search value ${i}`);
// const historiesSearchData = [];

const HeaderLayout = () => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { currentPage, currentMenu } = getMenuPath(pathname);
  const { user, openLoginModal, logout } = useAuth();
  const { searchValue, onSearchChange, showSearchMobile, setShowSearchMobile } = useSearch();

  const handleOpenNewTab = (path) => {
    const currentUrl = window.location.origin;

    window.open(`${currentUrl}/${path}`, "_blank");
  };

  const onClickProfileMenu = (key) => {
    if (key === "profile") navigate(`${pathData.profile}/${user.user_id}`);
    else if (key === "logout") logout();
  };

  const clearNewfeed = () => {
    localStorage.removeItem(localStorage_fields.posts);
    localStorage.removeItem(localStorage_fields.lastIndexPost);
  };

  // mobile menu
  const [open, setOpen] = useState(false);

  const showMobileMenu = () => {
    setOpen(true);
  };

  const onCloseMobileMenu = () => {
    setOpen(false);
  };

  const handleClickMobileMenu = (menu) => {
    setOpen(false);
    if (currentPage === pathData.explore) clearNewfeed();
    navigate(menu);
  };

  const handleReloadNewFeed = () => {
    if (currentPage === pathData.explore) {
      clearNewfeed();
      navigate(pathData.explore);
    }
  };

  // hanle search mobile
  const [searchValueLocal, setSearchValueLocal] = useState(searchValue);
  // const [openSearchMenu, setOpenSearchMenu] = useState(false);
  // const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  // const [searchHistories, setSearchHistories] = useState(historiesSearchData);

  // const deleteSearchHistory = (index) => {
  //   setSearchHistories((prev) => prev.filter((_e, i) => i !== index));
  // };

  const handleSearchMobile = (value) => {
    setSearchValueLocal(value);
    onSearchChange(value);

    // setOpenHistoryPopup(false);
  };

  const handleCloseSearchMobileMenu = () => {
    // setOpenHistoryPopup(false);
    setSearchValueLocal(null);
    onSearchChange(null);
    setShowSearchMobile(false);
  };

  // handle create post
  const [showCreatePost, setShowCreatePost] = useState(false);

  const handleCreatePost = () => {
    setShowCreatePost(false);
  };

  // Go back to the previous page
  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(pathData.home);
    }
  };

  const needHideBackgroundOrTextWhite =
    currentPage === pathData.explore || currentPage === pathData.searchDetail || currentPage === pathData.postDetail;

  const showIconMenu =
    currentPage === pathData.home ||
    currentPage === pathData.search ||
    currentPage === pathData.brand ||
    currentPage === pathData.perfume;

  const showCreateBtn = user && currentPage === pathData.explore;

  // handle search desktop
  const handleSearch = (value) => {
    onSearchChange(value);
    if (value && currentPage !== pathData.home) navigate(pathData.home);
  };

  const goToHome = () => {
    onSearchChange(null);
    navigate(pathData.home);
  };

  if (isMobileView) {
    return (
      <>
        <Flex
          justify="space-between"
          align="center"
          style={{
            background: needHideBackgroundOrTextWhite ? "transparent" : "var(--white-color)",
          }}
          className={`vfra-header-container ${needHideBackgroundOrTextWhite ? "text-bold-white" : ""}`}>
          {showIconMenu && (
            <Button
              className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
              onClick={showMobileMenu}
              type="text"
              shape="circle"
              icon={<MenuOutlined />}
            />
          )}

          {!showIconMenu && (
            <Button
              className={`${needHideBackgroundOrTextWhite ? "text-bold-white" : ""}`}
              onClick={handleBack}
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined />}
            />
          )}

          <Flex gap={6} justify="center" align="center">
            <span style={{ fontSize: "1rem", fontWeight: "600" }} onClick={handleReloadNewFeed}>
              {currentMenu}
            </span>
            {showCreateBtn && (
              <Button
                size="small"
                title="create post"
                onClick={() => setShowCreatePost(true)}
                shape="circle"
                icon={<PlusOutlined />}></Button>
            )}
          </Flex>

          <Button
            className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
            onClick={() => setShowSearchMobile(true)}
            type="text"
            shape="circle"
            icon={<SearchOutlined />}
          />
        </Flex>

        <Modal width="100%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
          <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
        </Modal>

        {showIconMenu && (
          <Drawer
            getContainer={document.getElementById("root")}
            title={
              <Flex align="center" justify="space-between">
                <Flex align="center" gap={12}>
                  <img height={50} onClick={() => navigate(pathData.explore)} src={logoMobile} alt="" />
                  <img
                    className={currentPage === pathData.home ? "vfra-header-logo-text-active" : ""}
                    style={{ cursor: "pointer" }}
                    width={96}
                    onClick={goToHome}
                    src={logoWeb}
                    alt=""
                  />
                </Flex>

                <Button onClick={onCloseMobileMenu} type="text" shape="circle" icon={<CloseOutlined />} />
              </Flex>
            }
            placement="left"
            open={open}
            closable={false}
            onClose={onCloseMobileMenu}
            width="60%">
            <Flex vertical gap={24}>
              {pathMenus
                .filter((e) => e.visible)
                .map((item) => (
                  <span
                    key={item.id}
                    className={currentPage === item.path ? "vfra-menu-item vfra-menu-active" : "vfra-menu-item"}
                    onClick={() => handleClickMobileMenu(item.path)}>
                    {item.label}
                  </span>
                ))}

              {user && (
                <>
                  <Flex align="center" gap={6}>
                    <Avatar src={user.vfra_user?.avatar ?? user.avatar_url} />
                    <span style={{ fontWeight: "bold" }}>{user.vfra_user?.name ?? user.name}</span>
                  </Flex>

                  <span className="vfra-menu-item" onClick={() => onClickProfileMenu("profile")}>
                    Profile
                  </span>

                  <span className="vfra-menu-item" onClick={() => onClickProfileMenu("logout")}>
                    Logout
                  </span>
                </>
              )}

              {!user && (
                <span className="vfra-menu-item" onClick={openLoginModal}>
                  Login
                </span>
              )}

              <Flex gap={6} align="center" style={{ paddingLeft: "4px" }}>
                <span onClick={() => handleOpenNewTab("policy")} className="vfra-header-sub-menu-item-btn">
                  Privacy policies
                </span>
                <span aria-hidden="true">·</span>
                <span onClick={() => handleOpenNewTab("terms")} className="vfra-header-sub-menu-item-btn">
                  Terms
                </span>
              </Flex>
            </Flex>
            <Divider>
              <span className="vfra-header-sub-menu-item">Scentub © {new Date().getFullYear()}</span>
            </Divider>
          </Drawer>
        )}

        <Drawer
          getContainer={document.getElementById("root")}
          title={
            <Flex align="center" justify="space-between" gap={6}>
              {/* <Popover
                placement="bottom"
                title={null}
                open={openHistoryPopup}
                content={
                  <Flex vertical gap={12} style={{ padding: "0 3px" }}>
                    {searchHistories.length > 0 &&
                      searchHistories.map((e, index) => (
                        <Flex key={index} gap={12} justify="space-between" align="center">
                          <ClockCircleOutlined />
                          <Flex
                            className="vfra-search-history-item"
                            justify="start"
                            flex={1}
                            onClick={() => handleSearchMobile(e)}>
                            <p style={{ fontSize: "1.2rem", fontWeight: "500" }}>{e}</p>
                          </Flex>
                          <Button type="text" shape="circle" onClick={() => deleteSearchHistory(index)}>
                            <CloseOutlined />
                          </Button>
                        </Flex>
                      ))}
                  </Flex>
                }>
                <Input
                  allowClear
                  size="large"
                  className="vfra-search-main-mobile"
                  value={searchValueLocal}
                  onFocus={() => setOpenHistoryPopup(true)}
                  onChange={(e) => setSearchValueLocal(e.target.value)}
                  onPressEnter={(e) => handleSearchMobile(e.target.value)}
                  addonBefore={<SearchOutlined />}
                  placeholder="Search posts, perfumes, brands..."
                />
              </Popover> */}

              <Input
                allowClear
                size="large"
                className="vfra-search-main-mobile"
                value={searchValueLocal}
                // onFocus={() => setOpenHistoryPopup(true)}
                onChange={(e) => setSearchValueLocal(e.target.value)}
                onPressEnter={(e) => handleSearchMobile(e.target.value)}
                addonBefore={<SearchOutlined />}
                placeholder="Search posts, perfumes, brands..."
              />

              <Button
                style={{ marginRight: "-6px" }}
                onClick={handleCloseSearchMobileMenu}
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
              />
            </Flex>
          }
          placement="right"
          open={showSearchMobile}
          closable={false}
          width="100%">
          <MultiSearch />
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <Row className="vfra-header-container" align="middle">
          <Col span={6}>
            <Flex align="center" gap={12}>
              <img
                className={currentPage === pathData.home ? "vfra-header-logo-text-active" : ""}
                style={{ cursor: "pointer", width: "6rem" }}
                onClick={goToHome}
                src={logoWeb}
                alt=""
              />
              <div
                className={`vfra-header-menu-btn ${
                  currentPage === pathData.explore ? "vfra-header-menu-btn-active" : ""
                }`}
                onClick={() => navigate(`${pathData.explore}`)}>
                <span>Explore</span>
              </div>
            </Flex>
          </Col>

          <Col span={12}>
            <Flex justify="center">
              <Search
                allowClear
                size="large"
                className="vfra-search-main"
                placeholder="Search posts, perfumes, brands..."
                defaultValue={searchValue}
                onSearch={handleSearch}
              />
            </Flex>
          </Col>

          <Col span={6} justify="end">
            <Flex justify="end" align="center" gap={12}>
              {showCreateBtn && (
                <Button
                  title="create post"
                  onClick={() => setShowCreatePost(true)}
                  shape="round"
                  icon={<PlusOutlined />}>
                  Create
                </Button>
              )}

              <Popover
                placement="bottomRight"
                content={
                  <Flex vertical gap={12}>
                    {user && (
                      <Flex vertical>
                        <Flex align="center" gap={12}>
                          <Avatar size="large" src={user.vfra_user?.avatar ?? user.avatar_url} />
                          <h3 className="one-line-ellipsis">{user.vfra_user?.name ?? user.name}</h3>
                        </Flex>
                        <Button
                          onClick={() => navigate(`${pathData.profile}/${user.user_id}`)}
                          shape="round"
                          size="small"
                          style={{ fontWeight: "500" }}>
                          Profile
                        </Button>
                      </Flex>
                    )}

                    {user && (
                      <Flex onClick={() => logout()} align="center" className="vfra-header-menu-item" gap={6}>
                        <LogoutOutlined />
                        <span>Logout</span>
                      </Flex>
                    )}

                    {!user && (
                      <Flex onClick={openLoginModal} align="center" className="vfra-header-menu-item" gap={6}>
                        <LoginOutlined />
                        <span>Login</span>
                      </Flex>
                    )}

                    <Flex gap={6} align="center">
                      <span onClick={() => handleOpenNewTab("policy")} className="vfra-header-sub-menu-item-btn">
                        Privacy policies
                      </span>
                      <span aria-hidden="true">·</span>
                      <span onClick={() => handleOpenNewTab("terms")} className="vfra-header-sub-menu-item-btn">
                        Terms
                      </span>
                      <span aria-hidden="true">·</span>
                      <span className="vfra-header-sub-menu-item">Scentub © {new Date().getFullYear()}</span>
                    </Flex>
                  </Flex>
                }
                title={null}
                trigger="click">
                <Flex align="center" gap={12} style={{ padding: "6px 0" }}>
                  {user && (
                    <Avatar
                      style={{ cursor: "pointer" }}
                      size="large"
                      src={user.vfra_user?.avatar ?? user.avatar_url}
                    />
                  )}

                  {!user && <Avatar style={{ cursor: "pointer" }} size="large" icon={<UserOutlined />} />}
                </Flex>
              </Popover>
            </Flex>
          </Col>
        </Row>

        <Modal width="70%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
          <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
        </Modal>
      </>
    );
  }
};

export default HeaderLayout;
