import { Flex } from "antd";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PopularPerfumes from "./PopularPerfumes";
import TopReviewers from "./TopReviewers";
import TopComments from "./TopComments";
import PostList from "./PostList";

const ScentubExplore = () => {
  const { isMobileView } = useWindowSize();

  return (
    <Flex vertical gap={isMobileView ? "0" : "1.5rem"} style={{ marginBottom: "1rem" }}>
      <PopularPerfumes />
      <TopReviewers />
      <TopComments />
      <PostList />
    </Flex>
  );
};

export default ScentubExplore;
