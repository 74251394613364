import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, Progress, message } from "antd";
import { winterIcon, leafIcon, summerIcon, fallIcon } from "../../../../../../utils/svgIcons";
import CustomeSvgIcon from "../../../../../../components/common/CustomSvgIcon";
import CustomeSecondLabel from "../../../../../../components/common/CustomeSecondLabel";
import { getMaxValueFromObject, colors } from "../../../../../../utils/service";
import { useAuth } from "../../../../../../components/contexts/AuthContext";
import { addVotes } from "../../../../../../services/supabaseClient";
import useWindowSize from "../../../../../../components/hooks/useWindowSize";

const progressStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "8px",
};

const styleIcon = { marginBottom: "12px", fill: colors.grayBackgroundColor };
const styleIconActive = { marginBottom: "12px", fill: colors.mainThemeColor };

const porgressPercentPosition = {
  type: "none",
};

const SeasonVote = ({ data, userVote }) => {
  const { isMobileView } = useWindowSize();

  const { user } = useAuth();
  const { perfumeId } = useParams();

  const [spring, setSpring] = useState(null);
  const [summer, setSummer] = useState(null);
  const [fall, setFall] = useState(null);
  const [winter, setWinter] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const [valueVote, setValueVote] = useState(null);
  const [dataUserVote, setDataUserVote] = useState(null);

  const setInitValues = (dataInit, initUserVote) => {
    setDataVote(dataInit);
    const max = getMaxValueFromObject(dataInit);

    const initSpring = dataInit?.spring ? (dataInit.spring / max) * 100 : 0;
    setSpring(initSpring);

    const initSummer = dataInit?.summer ? (dataInit.summer / max) * 100 : 0;
    setSummer(initSummer);

    const initFall = dataInit?.fall ? (dataInit.fall / max) * 100 : 0;
    setFall(initFall);

    const initWinter = dataInit?.winter ? (dataInit.winter / max) * 100 : 0;
    setWinter(initWinter);

    if (initUserVote && initUserVote.seasons) {
      setValueVote(initUserVote.seasons);
      setDataUserVote(initUserVote);
    } else {
      setValueVote(null);
      setDataUserVote({ seasons: null });
    }
  };

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote]);

  const updateDataVote = (value, newStatus, newDataUserVotes) => {
    const newValueVote = { ...valueVote, [value]: newStatus };
    setValueVote(newValueVote);

    const newDataVotes = {
      ...dataVote,
      [value]: newStatus ? dataVote[value] + 1 : dataVote[value] - 1,
    };

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onVote = async (value) => {
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        let newStatus = true;
        if (dataUserVote?.seasons?.hasOwnProperty(value)) {
          newStatus = !dataUserVote?.seasons[value];
        }

        const votes = {
          seasons: {
            ...dataUserVote.seasons,
            [value]: newStatus,
          },
        };

        const { error } = addVotes(perfumeId, votes);

        if (error) {
          message.error(error.message);
        } else {
          setValueVote(value);
          updateDataVote(value, newStatus, votes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const progressSize = [isMobileView ? 60 : 70, 3];
  const iconHeight = !isMobileView ? "1.875rem" : 20;

  return (
    <>
      <Flex justify="space-around">
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("spring")}>
            <CustomeSvgIcon
              style={valueVote?.spring ? styleIconActive : styleIcon}
              height={iconHeight}
              icon={leafIcon}
            />
            <Flex vertical align="center">
              <span>spring</span>
              <CustomeSecondLabel label={dataVote ? dataVote.spring : 0} />
            </Flex>
          </Flex>

          <Progress
            style={progressStyle}
            percent={spring}
            percentPosition={porgressPercentPosition}
            size={progressSize}
            strokeColor={colors.mainThemeColor}
          />
        </Flex>
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("summer")}>
            <CustomeSvgIcon
              style={valueVote?.summer ? styleIconActive : styleIcon}
              height={iconHeight}
              icon={summerIcon}
            />
            <Flex vertical align="center">
              <span>summer</span>
              <CustomeSecondLabel label={dataVote ? dataVote.summer : 0} />
            </Flex>
          </Flex>

          <Progress
            style={progressStyle}
            percent={summer}
            percentPosition={porgressPercentPosition}
            size={progressSize}
            strokeColor={colors.mainThemeColor}
          />
        </Flex>
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("fall")}>
            <CustomeSvgIcon
              style={valueVote?.fall ? styleIconActive : styleIcon}
              height={iconHeight}
              width="2.5rem"
              icon={fallIcon}
            />
            <Flex vertical align="center">
              <span>fall</span>
              <CustomeSecondLabel label={dataVote ? dataVote.fall : 0} />
            </Flex>
          </Flex>

          <Progress
            style={progressStyle}
            percent={fall}
            percentPosition={porgressPercentPosition}
            size={progressSize}
            strokeColor={colors.mainThemeColor}
          />
        </Flex>
        <Flex vertical align="center">
          <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("winter")}>
            <CustomeSvgIcon
              style={valueVote?.winter ? styleIconActive : styleIcon}
              height={iconHeight}
              icon={winterIcon}
            />
            <Flex vertical align="center">
              <span>winter</span>
              <CustomeSecondLabel label={dataVote ? dataVote.winter : 0} />
            </Flex>
          </Flex>

          <Progress
            style={progressStyle}
            percent={winter}
            percentPosition={porgressPercentPosition}
            size={progressSize}
            strokeColor={colors.mainThemeColor}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SeasonVote;
