import "./index.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../components/contexts/AuthContext";
import { Radio, Flex, message } from "antd";
import CustomProgress from "../../../../../../components/common/CustomProgress";
import { getTotalFromObject } from "../../../../../../utils/service";
import { addVotes } from "../../../../../../services/supabaseClient";

const radioGroupStyle = { width: "100%", marginBottom: "12px" };
const radioStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  fontSize: " 0.8125rem",
};

const GenderVote = ({ data, userVote }) => {
  const { perfumeId } = useParams();
  const { user } = useAuth();

  const [female, setFemale] = useState(null);
  const [unisex, setUnisex] = useState(null);
  const [male, setMale] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const [valueVote, setValueVote] = useState(null);

  const setInitValues = (dataInit, initUserVote) => {
    setDataVote(dataInit);
    const total = getTotalFromObject(dataInit);

    const initFemale = dataInit?.female ? (dataInit.female / total) * 100 : 0;
    setFemale(initFemale);

    const initUnisex = dataInit?.unisex ? (dataInit.unisex / total) * 100 : 0;
    setUnisex(initUnisex);

    const initMale = dataInit?.male ? (dataInit.male / total) * 100 : 0;
    setMale(initMale);

    if (initUserVote && initUserVote.genders) {
      setValueVote(initUserVote.genders);
    } else {
      setValueVote(null);
    }
  };

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote]);

  const updateDataVote = (oldValueVote, newValueVote, newDataUserVotes) => {
    const newDataVotes = {
      ...dataVote,
      [newValueVote]: dataVote[newValueVote] + 1,
    };

    if (oldValueVote && dataVote[oldValueVote] > 0) {
      newDataVotes[oldValueVote] = dataVote[oldValueVote] - 1;
    }

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onChange = async (e) => {
    const { value } = e.target;
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        const votes = {
          genders: {
            [value]: true,
          },
        };

        const { error } = addVotes(perfumeId, votes);

        if (error) {
          message.error(error.message);
        } else {
          setValueVote(value);

          const newDataUserVotes = {
            genders: value,
          };

          updateDataVote(valueVote, value, newDataUserVotes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const isShowProgress = female + unisex + male > 0 ? true : false;

  return (
    <>
      <Radio.Group style={radioGroupStyle} onChange={onChange} value={valueVote}>
        <Flex justify="space-between">
          <Radio className="vfra-radio-style" value="female" style={radioStyle}>
            female{" "}
            <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.female : 0})</span>
          </Radio>
          <Radio className="vfra-radio-style" value="unisex" style={radioStyle}>
            unisex{" "}
            <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.unisex : 0})</span>
          </Radio>
          <Radio className="vfra-radio-style" value="male" style={radioStyle}>
            male <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.male : 0})</span>
          </Radio>
        </Flex>
      </Radio.Group>
      {isShowProgress && <CustomProgress data={[female?.toFixed(0), unisex?.toFixed(0), male?.toFixed(0)]} />}
    </>
  );
};

export default GenderVote;
