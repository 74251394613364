import "./index.css";
import { Skeleton, Row, Col } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import { useSearch } from "../../../../components/contexts/SearchContext";

const PerfumeLoading = () => {
  const { isMobileView } = useWindowSize();
  const { showSearchMobile } = useSearch();

  return (
    <>
      {!showSearchMobile && <br></br>}
      <Row className="vfra-perfumes-loading-container" gutter={!isMobileView ? [48, 48] : [12, 12]}>
        {Array.from({ length: isMobileView ? 8 : 20 }).map((_, i) => (
          <Col key={i} span={!isMobileView ? 6 : 24}>
            <Skeleton.Node active={true} style={{ height: "90px" }} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PerfumeLoading;
