import { useState, useCallback, useEffect, memo } from "react";
import { Flex, Row, Col, Button, message, Empty } from "antd";
import { search_brands } from "../../../services/meilisearchBrands";
import useWindowSize from "../../../components/hooks/useWindowSize";
import BrandItem from "../BrandItem";
import BrandsLoading from "./BrandsLoading";

const SearchBrands = memo(({ searchValue }) => {
  const { isMobileView } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const onSearch = useCallback(async () => {
    try {
      setLoading(true);
      const searchResults = await search_brands(searchValue, page);

      setTotal(searchResults.estimatedTotalHits);
      if (page > 1) {
        setBrands((prevBrands) => [...prevBrands, ...searchResults.hits]);
      } else {
        setBrands(searchResults.hits);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [searchValue, page]);

  useEffect(() => {
    if (searchValue) {
      onSearch();
    }
  }, [searchValue, onSearch]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {loading && <BrandsLoading />}

      {!isMobileView && <br></br>}

      {!loading && brands.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No brands found!</p>
            </Flex>
          }
        />
      )}

      {!loading && brands.length > 0 && (
        <Row gutter={!isMobileView ? [6, 6] : [0, 0]} justify="center" vertical="vertical">
          {brands.map((item) => {
            return (
              <Col key={item.id} span={!isMobileView ? 6 : 24}>
                <BrandItem designer={item} />
              </Col>
            );
          })}
        </Row>
      )}

      {!loading && brands.length < total && (
        <Flex justify="center" style={{ marginTop: "12px" }}>
          <Button shape="round" onClick={handleLoadMore} loading={loading} iconPosition="end">
            More
          </Button>
        </Flex>
      )}

      <br></br>
    </>
  );
});

export default SearchBrands;
