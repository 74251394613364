import { useNavigate } from "react-router-dom";
import { Card, Flex } from "antd";
import { useSearch } from "../../../../components/contexts/SearchContext";
import { pathData } from "../../../../utils/routes";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const cardSearchStyle = {
  height: "5.625rem",
};

const cardBodyStyle = {
  padding: "0.5rem",
};

const PerfumeItem = ({ item }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const { setShowSearchMobile } = useSearch();
  const handleClick = () => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.perfume}/${item.pid}`);
  };

  return (
    <Card hoverable style={cardSearchStyle} bordered={false} onClick={handleClick} styles={{ body: cardBodyStyle }}>
      <Flex justify="start" align="center" gap={8}>
        <img alt="" src={item.image_url} style={{ width: "3rem" }} />
        <Flex vertical>
          <span style={{ fontWeight: "700", fontSize: "0.9rem" }} className="one-line-ellipsis">
            {item.name}
          </span>
          <span className="one-line-ellipsis" style={{ fontSize: "0.8rem" }}>
            {item.brand_name}
          </span>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PerfumeItem;
