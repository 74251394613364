import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Flex, Typography, message, Spin, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { getBrandById } from "../../../services/supabaseClient";
import ListPerfumes from "./ListPerfumes";
import useWindowSize from "../../../components/hooks/useWindowSize";

const imgStyle = { display: "block", width: "100%" };
const flexStyle = { marginTop: "0.5rem" };

const BrandDetails = () => {
  const { isMobileView } = useWindowSize();
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchDataBrand = useCallback(async () => {
    try {
      const { data, error } = await getBrandById(brandId);
      if (error) {
        message.error(error.message);
      } else {
        setData(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  useEffect(() => {
    fetchDataBrand();
  }, [brandId, fetchDataBrand]);

  const titleStyle = { marginRight: "0.75rem", fontWeight: "500", fontSize: isMobileView ? "12px" : "0.8rem" };
  const valueStyle = { marginRight: "0.75rem", fontWeight: "500", fontSize: isMobileView ? "14px" : "0.9rem" };

  return (
    <>
      <Breadcrumb
        items={[
          {
            href: "/home",
            title: <HomeOutlined />,
          },
          {
            title: "Brands",
          },
        ]}
      />

      {loading && (
        <Flex justify="center" style={{ marginTop: "20%" }}>
          <Spin size="large" />
        </Flex>
      )}

      {!loading && !data && (
        <Flex justify="center" style={{ marginTop: "2rem" }}>
          <p style={{ color: "rgb(100 100 100)" }}>No brand found!</p>
        </Flex>
      )}

      {!loading && data && (
        <Row gutter={[32]} justify="center">
          <Col span={!isMobileView ? 16 : 24}>
            <Flex justify="center">{!isMobileView ? <h1>{data.brand_name}</h1> : <h2>{data.brand_name}</h2>}</Flex>
            <Row gutter={[32, 32]}>
              <Col span={!isMobileView ? 8 : 24}>
                <Flex justify="center" style={{ marginBottom: "1.5rem" }}>
                  <img alt={data.brand_name} src={data.logo_url} style={imgStyle} />
                </Flex>
                <Flex style={flexStyle}>
                  <span style={titleStyle}>Country:</span>
                  <span style={valueStyle}>{data.country}</span>
                </Flex>
                <Flex style={flexStyle}>
                  <span style={titleStyle}>Main activity:</span>
                  <span style={valueStyle}>{data.main_activity}</span>
                </Flex>
                <Flex style={flexStyle}>
                  <span style={titleStyle}>Brand website:</span>
                  <a href={data.brand_website} style={valueStyle}>
                    Link
                  </a>
                </Flex>
                <Flex style={flexStyle}>
                  <span style={titleStyle}>Parent company:</span>
                  <span style={valueStyle}>{data.parent_company}</span>
                </Flex>
              </Col>
              <Col span={!isMobileView ? 16 : 24}>
                <Typography.Paragraph
                  ellipsis={{
                    fontSize: "0.8125rem",
                    rows: !isMobileView ? 16 : 8,
                    expandable: "collapsible",
                    symbol: (expanded) => (expanded ? "less" : "more"),
                  }}
                  style={{ lineHeight: "1.5", fontWeight: "400", fontSize: "1rem" }}>
                  {data.description}
                </Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={!isMobileView ? 16 : 24}>
            <ListPerfumes perfumes={data.perfume_list} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BrandDetails;
