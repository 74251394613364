import "./index.css";
import { Tabs } from "antd";
import { SolutionOutlined, ShoppingOutlined, ShopOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { pathData } from "../../utils/routes";
import PostList from "./PostList";
import Brands from "../Brands";
import Perfumes from "../Perfumes";
import useWindowSize from "../../components/hooks/useWindowSize";

const MultiSearch = () => {
  const { isMobileView } = useWindowSize();
  const menu = [
    {
      key: "perfume",
      label: `Perfumes`,
      children: <Perfumes />,
      icon: !isMobileView ? <ShoppingOutlined /> : null,
    },
    {
      key: "post",
      label: `Posts`,
      children: <PostList />,
      icon: !isMobileView ? <SolutionOutlined /> : null,
    },
    {
      key: "brand",
      label: `Brands`,
      children: <Brands />,
      icon: !isMobileView ? <ShopOutlined /> : null,
    },
  ];

  const { tabId } = useParams();
  const navigate = useNavigate();

  let activeTab = "perfume";

  if (tabId === "perfume" || tabId === "post" || tabId === "brand") activeTab = tabId;

  const handleTabChange = (value) => {
    navigate(`${pathData.home}/${value}`);
  };

  return (
    <Tabs
      onChange={handleTabChange}
      size="small"
      centered
      className="vfra-multisearch-tabs"
      activeKey={activeTab}
      items={menu}
    />
  );
};

export default MultiSearch;
