import { Col, Row, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import PerfumeDetail from "./PerfumeDetail";
import Comments from "./Comments";
import useWindowSize from "../../../components/hooks/useWindowSize";
import RelatedPerfumes from "./RelatedPerfumes";

const Perfume = () => {
  window.scrollTo(0, 0);
  const { isMobileView } = useWindowSize();

  return (
    <>
      <Breadcrumb
        style={{ display: isMobileView ? "block" : "none" }}
        items={[
          {
            href: "/home",
            title: <HomeOutlined />,
          },
          {
            title: "Perfume",
          },
        ]}
      />

      {isMobileView && <br></br>}

      <Row gutter={[24, 12]}>
        <Col span={24}>
          <PerfumeDetail />
        </Col>
        <Col offset={!isMobileView ? 6 : 0} span={!isMobileView ? 12 : 24}>
          <Comments />
        </Col>
        <Col span={24}>
          <RelatedPerfumes />
        </Col>
      </Row>
    </>
  );
};

export default Perfume;
