import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import { pathData } from "../../../../../utils/routes";

const PerfumeItem = ({ item }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${pathData.perfume}/${item.pid}`);
  };

  return (
    <div className="vfra-perfume-relate-perfume-container-item">
      <Flex justify="start" align="center" gap={8} onClick={handleClick} className="vfra-perfume-relate-perfume-item">
        <img alt="" src={item.image_url} style={{ width: "3rem" }} />
        <Flex vertical>
          <span style={{ fontWeight: "700", fontSize: "0.9rem" }} className="one-line-ellipsis">
            {item.name}
          </span>
          <span className="one-line-ellipsis" style={{ fontSize: "0.8rem" }}>
            {item.brand_name}
          </span>
        </Flex>
      </Flex>
    </div>
  );
};

export default PerfumeItem;
