import { useParams } from "react-router-dom";
import { useState, useCallback, useMemo } from "react";
import { Flex, Divider, List, Input, Spin, message, Pagination } from "antd";
import _ from "lodash";
import { prevLoadImage, formatNumber } from "../../../../utils/service";
import { search_perfumes_by_brand_id } from "../../../../services/meilisearchPerfumes";
import Perfume from "./Perfume";

const searchStyle = { width: "70%", marginBottom: "0.75rem" };

const ListPerfumes = ({ perfumes }) => {
  const { brandId } = useParams();
  prevLoadImage(perfumes, "image_url");
  const countPerfumes = formatNumber(perfumes.length);

  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = useState(1);
  const [ResultPerfumes, setResultPerfumes] = useState([]);
  const [totalPerfumes, setTotalPerfumes] = useState(0);
  const [searchMode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    async (valueSearch, newPage = 1) => {
      try {
        setLoading(true);
        const searchResults = await search_perfumes_by_brand_id(valueSearch, newPage, brandId);

        setSearchMode(true);
        setTotalPerfumes(searchResults.estimatedTotalHits);
        setResultPerfumes(searchResults.hits);
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [brandId]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    if (!value) {
      setSearchMode(false);
      setResultPerfumes([]);
    } else {
      setPage(1);
      debouncedSearch(value);
    }

    setSearchValue(value);
  };

  const debouncedSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onSearch(searchValue, newPage);
  };

  return (
    <>
      <Divider style={{ fontSize: "0.9rem" }}>
        Fragrances ({searchMode ? formatNumber(totalPerfumes) : countPerfumes})
      </Divider>
      <Flex justify="center" align="center">
        <Input
          allowClear
          value={searchValue}
          onChange={handleChange}
          size="large"
          placeholder="Search perfumes by name, description..."
          style={searchStyle}
          suffix={loading && <Spin />}
        />
      </Flex>

      {!searchMode && (
        <List
          pagination={{
            position: "bottom",
            align: "center",
          }}
          dataSource={perfumes}
          renderItem={(item) => (
            <List.Item key={item.pid} style={{ borderBlockEnd: "none" }}>
              <Perfume item={item} />
            </List.Item>
          )}
        />
      )}

      {searchMode && (
        <>
          <List
            dataSource={ResultPerfumes}
            renderItem={(item) => (
              <List.Item key={item.pid} style={{ borderBlockEnd: "none" }}>
                <Perfume item={item} />
              </List.Item>
            )}
          />
          <Pagination
            style={{ marginTop: "0.75rem" }}
            align="center"
            currentPage={page}
            total={totalPerfumes}
            onChange={handlePageChange}
          />
        </>
      )}
      <br></br>
    </>
  );
};

export default ListPerfumes;
