import "./index.css";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../components/contexts/AuthContext";
import { Divider, Flex, Radio, message } from "antd";
import { addVotes } from "../../../../../../services/supabaseClient";
import VerticalProgress from "../../../../../../components/common/VerticalProgress/VerticalProgress";
import useWindowSize from "../../../../../../components/hooks/useWindowSize";

const Vote = ({ vote, data, userVote }) => {
  const { isMobileView } = useWindowSize();
  const { perfumeId } = useParams();
  const { user } = useAuth();

  const [dataVote, setDataVote] = useState(null);

  const [valueShow, setValueShow] = useState(0);
  const [valueVote, setValueVote] = useState(null);

  const setInitValues = useCallback(
    (dataInit, initUserVote) => {
      setDataVote(dataInit);

      const percent = dataInit ? (dataInit[vote.id]?.value / (vote.list.length - 1)) * 100 : 0;
      setValueShow(percent.toFixed(0));

      if (initUserVote && initUserVote[vote.id]) {
        setValueVote(initUserVote[vote.id]);
      } else {
        setValueVote(null);
      }
    },
    [vote]
  );

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote, setInitValues]);

  const updateDataVote = (oldValueVote, newValueVote, newDataUserVotes) => {
    let count = dataVote[vote.id]?.count ?? 0;
    const oldTotal = count ? dataVote[vote.id].value * count : 0;
    let newAverageScore = 0;
    if (oldValueVote) {
      newAverageScore = (oldTotal - oldValueVote + newValueVote) / count;
    } else {
      count = count + 1;
      newAverageScore = (oldTotal + newValueVote) / count;
    }

    const newDataVotes = {
      ...dataVote,
      [vote.id]: {
        count,
        value: newAverageScore,
      },
    };

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onChange = async (e) => {
    const { value } = e.target;
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        const votes = {
          [vote.id]: value,
        };

        const { error } = addVotes(perfumeId, votes);

        if (error) {
          message.error(error.message);
        } else {
          setValueVote(value);
          updateDataVote(valueVote, value, votes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const voteHeight = !isMobileView ? "11.25rem" : 130;

  const totalVote = dataVote && vote && dataVote[vote.id]?.count ? dataVote[vote.id]?.count : 0;

  return (
    <>
      <Divider>
        <span style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          {vote.name} <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({totalVote})</span>
        </span>
      </Divider>

      <Flex justify="center">
        <Radio.Group value={valueVote} onChange={onChange} style={{ marginRight: !isMobileView ? "6px" : "0px" }}>
          <Flex vertical justify="space-between" style={{ height: voteHeight }}>
            {vote.list.map((item, index) => {
              return (
                <Radio key={item.title} value={vote.list.length - index - 1} className="vfra-radio-style">
                  {item.title}
                </Radio>
              );
            })}
          </Flex>
        </Radio.Group>
        <VerticalProgress percent={valueShow} height={voteHeight} width={"3px"} showText={true} />
      </Flex>
    </>
  );
};

export default Vote;
