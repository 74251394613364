import "./index.css";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Divider, Row, Col, Flex, message, Skeleton, Empty } from "antd";
import { getPerfumes } from "../../../services/supabaseClient";
import useWindowSize from "../../../components/hooks/useWindowSize";

const Favorites = () => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [likedPerfumes, setLikedPerfumes] = useState([]);
  const [ratedPerfumes, setRatedPerfumes] = useState([]);
  const [commentedPerfumes, setCommentedPerfumes] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { error, data } = await getPerfumes(userId);
      if (error) {
        message.error(error);
      } else {
        const ratedPerfumesList = [];
        const likedPerfumesList = [];
        const commentedPerfumesList = [];

        if (data.length > 0) {
          data.forEach((e) => {
            if (e.tag.includes("RATED")) {
              ratedPerfumesList.push(e);
            } else if (e.tag.includes("LIKED")) {
              likedPerfumesList.push(e);
            } else if (e.tag.includes("COMMENTED")) {
              commentedPerfumesList.push(e);
            }
          });
        }

        setRatedPerfumes(ratedPerfumesList);
        setLikedPerfumes(likedPerfumesList);
        setCommentedPerfumes(commentedPerfumesList);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const imageHeight = 60;
  const titleStyle = { fontSize: "0.875rem", margin: "3rem 0" };
  const spanShelf = isMobileView ? 4 : 3;

  return (
    <div className="vfra-container">
      {isLoading && (
        <Skeleton
          paragraph={{
            rows: 10,
          }}
          active
        />
      )}

      {!isLoading && likedPerfumes.length === 0 && commentedPerfumes.length === 0 && ratedPerfumes.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {likedPerfumes.length > 0 && <Divider style={titleStyle}>Favorites ({likedPerfumes.length})</Divider>}
      {likedPerfumes.length > 0 && (
        <Row gutter={[0, 54]} justify="center" style={{ marginBottom: "3rem" }}>
          {likedPerfumes.map((e) => {
            return (
              <Col key={e.id} span={spanShelf}>
                <Flex justify="center">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/product/${e.id}`)}
                    className="perfume-on-shelf"
                    height={imageHeight}
                    alt="example"
                    src={e.image_url}
                  />
                </Flex>
                <div className="bottom-shelf"></div>
              </Col>
            );
          })}
        </Row>
      )}

      {commentedPerfumes.length > 0 && <Divider style={titleStyle}>Reviewed ({commentedPerfumes.length})</Divider>}
      {commentedPerfumes.length > 0 && (
        <Row gutter={[0, 54]} justify="center" style={{ marginBottom: "3rem" }}>
          {commentedPerfumes.map((e) => {
            return (
              <Col key={e.id} span={spanShelf}>
                <Flex justify="center">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/product/${e.id}`)}
                    className="perfume-on-shelf"
                    height={imageHeight}
                    alt="example"
                    src={e.image_url}
                  />
                </Flex>
                <div className="bottom-shelf"></div>
              </Col>
            );
          })}
        </Row>
      )}

      {ratedPerfumes.length > 0 && <Divider style={titleStyle}>Rated ({ratedPerfumes.length})</Divider>}
      {ratedPerfumes.length > 0 && (
        <Row gutter={[0, 54]} justify="center" style={{ marginBottom: "3rem" }}>
          {ratedPerfumes.map((e) => {
            return (
              <Col key={e.id} span={spanShelf}>
                <Flex justify="center">
                  <img
                    onClick={() => navigate(`/product/${e.id}`)}
                    className="perfume-on-shelf"
                    height={imageHeight}
                    alt="example"
                    src={e.image_url}
                  />
                </Flex>
                <div className="bottom-shelf"></div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default Favorites;
