import { Divider } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const DividerTitle = ({ title }) => {
  const { isMobileView } = useWindowSize();

  return (
    <Divider
      orientation="left"
      orientationMargin={isMobileView ? "0" : "12"}
      style={{
        marginBottom: isMobileView ? "1rem" : "1.5rem",
        fontSize: isMobileView ? "1rem" : "1.2rem",
        fontWeight: "600",
      }}>
      {title}
    </Divider>
  );
};

export default DividerTitle;
