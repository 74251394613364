import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Empty, Flex, message, Row, Col, Button, Skeleton } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import { colors } from "../../../utils/service";
import useWindowSize from "../../hooks/useWindowSize";
import PreviewPost from "./PreviewPost";
const PAGE_SIZE = 8;
const typeList = {
  published: "published",
  saved: "saved",
  loved: "loved",
  allRelatePost: "allRelatePost",
};

export const ListPost = ({
  type,
  title,
  emptyTitle,
  emptyContent,
  reload,
  fetchApi,
  deleteApi,
  successDeleteMessage,
}) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showLoadmore, setShowLoadmore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchData = useCallback(
    async (startTime = 0) => {
      if (startTime) setLoadingMore(true);
      try {
        let res = null;
        if (type === typeList.published) {
          res = await fetchApi(userId, startTime, PAGE_SIZE);
        } else if (type === typeList.saved) {
          res = await fetchApi("save", startTime, PAGE_SIZE);
        } else if (type === typeList.saved) {
          res = await fetchApi("heart", startTime, PAGE_SIZE);
        } else {
          res = await fetchApi("heart", startTime, PAGE_SIZE);
        }

        const { error, data } = res;
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            if (startTime !== 0) {
              setPosts((prev) => [...prev, ...data]);
            } else {
              setPosts(data);

              if (data.length < PAGE_SIZE) {
                setShowLoadmore(false);
              }
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [userId, fetchApi, type]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, reload]);

  const handleRemove = async (id) => {
    try {
      let res = null;
      if (type === typeList.published) {
        res = await deleteApi(id);
      } else if (type === "saved") {
        res = await deleteApi(id, "save");
      } else {
        res = await deleteApi(id, "heart");
      }

      const { error } = res;

      if (error) {
        message.error(error.message);
      } else {
        message.success(successDeleteMessage);

        if (type === typeList.published) {
          setPosts((prev) => {
            return prev.filter((post) => post.post.id !== id);
          });
        } else {
          setPosts((prev) => {
            return prev.filter((post) => post.id !== id);
          });
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleLoadmore = () => {
    let startTime = 0;
    if (posts.length > 0) {
      if (type === typeList.published) {
        startTime = posts[posts.length - 1].post.created_at;
      } else {
        startTime = posts[posts.length - 1].created_at;
      }
    }

    fetchData(new Date(startTime).getTime());
  };

  const rowGutter = isMobileView ? [6, 6] : [36, 36];
  const itemSpan = isMobileView ? 12 : 6;

  return (
    <>
      <Flex
        style={{ marginTop: isMobileView ? "6px" : "12px", fontWeight: "bold", marginLeft: isMobileView ? "6px" : 0 }}>
        <span>{title}</span>
      </Flex>

      {loading && (
        <Row gutter={rowGutter} style={{ marginTop: "6px", padding: "0 6px" }}>
          {[...new Array(4)].map((_e, i) => (
            <Col key={i} span={itemSpan} style={{ height: "40vh", width: "100%" }}>
              <Skeleton.Node active={true} style={{ width: "100%" }} />
            </Col>
          ))}
        </Row>
      )}

      {!loading && posts.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "1rem" }}>{emptyTitle}</p>
              {isYourself && <span style={{ fontSize: "0.8rem", color: "#606060" }}>{emptyContent}</span>}
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Row gutter={rowGutter} justify="start" style={{ marginTop: "6px", padding: "0 6px" }}>
          {posts.map((post) => (
            <Col span={itemSpan} key={type === typeList.published ? post.post.id : post.id}>
              <PreviewPost
                post={type === typeList.published ? post.post : post}
                showManageButton={true}
                onRemove={handleRemove}
              />
            </Col>
          ))}
        </Row>
      )}

      {!loading && showLoadmore && (
        <Flex justify="center" style={{ marginTop: "1rem" }}>
          <Button
            style={{ fontSize: "0.8rem", color: colors.primary }}
            size="small"
            shape="round"
            type="text"
            onClick={handleLoadmore}
            loading={loadingMore}>
            More
          </Button>
        </Flex>
      )}

      <br></br>
    </>
  );
};

export default ListPost;
