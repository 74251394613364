import "./index.css";
import { useState } from "react";
import { Flex, Button, message, Tag, Modal, Tabs } from "antd";
import { PlusOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { follow, unfollow } from "../../../../services/supabaseClient";
import { useAuth } from "../../../../components/contexts/AuthContext";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ListUsers from "./ListUsers";
const { confirm } = Modal;

const Follow = ({ vfraUser, isYourself, loading }) => {
  const { isMobileView } = useWindowSize();
  const { refreshVfraUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFollow = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        followee_id: vfraUser.id,
      };

      const { error } = await follow(payload);
      if (error) {
        message.error(error.message);
      } else {
        message.success("Follow successfuly!");
        refreshVfraUser();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUnfollow = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        followee_id: vfraUser.id,
      };

      const { error } = await unfollow(payload);
      if (error) {
        message.error(error.message);
      } else {
        message.success("Unfollow successfuly!");
        refreshVfraUser();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showUnfollowConfirm = (id) => {
    if (isSubmitting) {
      return;
    }

    return confirm({
      title: "Confirm unfollow",
      content: `Are you sure you want to unfollow ${vfraUser.name} ?`,
      centered: true,
      getContainer: document.getElementById("root"),
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onUnfollow(id);
      },
    });
  };

  // follow detail
  const [showFollowDetail, setShowFollowDetail] = useState(false);
  const [activeKey, setActiveKey] = useState("followers");

  const items = [
    {
      key: "followers",
      label: (
        <span>
          Followers
          <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
            {vfraUser ? vfraUser.follow_detail.total_followers : "0"}
          </span>
        </span>
      ),
      children: (
        <ListUsers
          type="FOLLOWERS"
          total={vfraUser ? vfraUser.follow_detail.total_followers : 0}
          onClose={() => setShowFollowDetail(false)}
        />
      ),
    },
    {
      key: "following",
      label: (
        <span>
          Following
          <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
            {vfraUser ? vfraUser.follow_detail.total_followees : "0"}
          </span>
        </span>
      ),
      children: (
        <ListUsers
          type="FOLLOWING"
          total={vfraUser ? vfraUser.follow_detail.total_followees : 0}
          onClose={() => setShowFollowDetail(false)}
        />
      ),
    },
  ];

  const handleShowFollowDetail = (key) => {
    setActiveKey(key);
    setShowFollowDetail(true);
  };

  return (
    <>
      <Flex justify="center" gap={12} vertical align="center" style={{ marginTop: "6px" }}>
        {!isYourself && !vfraUser.follow_detail.followed && (
          <Button
            size="small"
            type="primary"
            shape="round"
            style={{ fontSize: "0.8rem", padding: "1px 18px" }}
            icon={<PlusOutlined />}
            loading={isSubmitting}
            onClick={onFollow}>
            Follow
          </Button>
        )}

        {!isYourself && vfraUser.follow_detail.followed && (
          <Flex gap={12} align="center">
            <Tag
              color="processing"
              bordered={false}
              style={{ fontSize: "0.8rem", padding: "1px 16px", marginInlineEnd: 0 }}>
              Following
            </Tag>
            <Button
              loading={isSubmitting}
              onClick={showUnfollowConfirm}
              size="small"
              shape="circle"
              icon={<UserDeleteOutlined />}
            />
          </Flex>
        )}

        {!loading && (
          <Flex justify="center" gap={24}>
            <span className="vfra-follow" onClick={() => handleShowFollowDetail("followers")}>
              <span className="vfra-follow-count">{vfraUser && vfraUser.follow_detail.total_followers}</span> followers
            </span>
            <span className="vfra-follow" onClick={() => handleShowFollowDetail("following")}>
              <span className="vfra-follow-count">{vfraUser && vfraUser.follow_detail.total_followees}</span> following
            </span>
          </Flex>
        )}
      </Flex>

      <Modal
        centered
        closable={true}
        width={isMobileView ? "100%" : "30%"}
        open={showFollowDetail}
        footer={null}
        getContainer={false}
        onCancel={() => setShowFollowDetail(false)}>
        <Flex justify="center" style={{ fontWeight: "bold" }}>
          {vfraUser.name}
        </Flex>
        <Tabs activeKey={activeKey} items={items} onChange={(e) => setActiveKey(e)} />
      </Modal>
    </>
  );
};

export default Follow;
