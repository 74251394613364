import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Empty, Flex, message, Button, Skeleton, Divider } from "antd";
import { colors } from "../../../../../utils/service";
import { getPostByPerfume } from "../../../../../services/supabaseClient";
import PreviewPost from "../../../../../components/common/ListPost/PreviewPost";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const RelatedPosts = () => {
  const { isMobileView } = useWindowSize();
  const { perfumeId } = useParams();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showLoadmore, setShowLoadmore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const pageSize = isMobileView ? 4 : 8;

  const fetchData = useCallback(
    async (startAfterPost = null) => {
      if (startAfterPost) setLoadingMore(true);
      try {
        const { error, data } = await getPostByPerfume(perfumeId, pageSize, startAfterPost);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            if (startAfterPost) {
              setPosts((prev) => [...prev, ...data]);
            } else {
              if (data.length < pageSize) {
                setShowLoadmore(false);
              }
              setPosts(data);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startAfterPost) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [perfumeId, pageSize]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadmore = () => {
    const startAfterPost = posts[posts.length - 1].post.id;
    fetchData(startAfterPost);
  };

  const postSize = isMobileView ? { width: "45vw", height: "60vw" } : { width: "13vw", height: "23vw" };
  const itemGap = isMobileView ? 6 : 24;

  return (
    <>
      <Divider style={{ fontWeight: "bold", fontSize: "0.9rem" }}>Relate posts</Divider>

      {loading && (
        <Flex gap={itemGap} style={{ flexWrap: "wrap" }}>
          {[...new Array(10)].map((_e, i) => (
            <div key={i} style={postSize}>
              <Skeleton.Node active={true} style={{ width: "100%" }} />
            </div>
          ))}
        </Flex>
      )}

      {!loading && posts.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Relate Post empty</p>
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Flex gap={itemGap}>
          {posts.map((post) => (
            <PreviewPost style={postSize} key={post.post.id} post={post.post} showManageButton={true} />
          ))}
        </Flex>
      )}

      {!loading && showLoadmore && (
        <>
          <br></br>
          <Flex justify="center" style={{ margin: "1rem 0" }}>
            <Button
              style={{ fontSize: "0.8rem", color: colors.primary }}
              size="small"
              shape="round"
              type="text"
              onClick={handleLoadmore}
              loading={loadingMore}>
              More
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default RelatedPosts;
