import "./index.css";
import { Flex, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { HeartOutlined, LikeOutlined } from "@ant-design/icons";
import { colors } from "../../../../../utils/service";
import { pathData } from "../../../../../utils/routes";
import { convertComment } from "../../../../../utils/service";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const Comment = ({ data, index, ...props }) => {
  const navigate = useNavigate();
  const comment = convertComment(data);
  const { isMobileView } = useWindowSize();

  const avtSize = isMobileView ? "3rem" : "2rem";
  const perfumeSize = isMobileView ? "4rem" : "4.5rem";
  const btnSize = isMobileView ? "small" : "middle";

  const hanldeClickUser = () => {
    navigate(`${pathData.profile}/${comment.user_id}`);
  };

  const hanldeClickPerfume = () => {
    data.perfume && navigate(`${pathData.perfume}/${data.perfume.id}`);
  };

  return (
    <Flex {...props} gap={12} align="center" className="vfra-explore-top-comment">
      <Flex vertical gap={12} style={{ flexGrow: 1 }}>
        <Flex gap={12} align="center">
          <div
            style={{
              width: avtSize,
              height: avtSize,
              borderRadius: "50%",
            }}
            onClick={hanldeClickUser}>
            <img
              style={{ width: avtSize, height: avtSize, borderRadius: "50%", cursor: "pointer" }}
              alt=""
              src={comment.user_avatar}
            />
          </div>

          <Flex vertical gap={0}>
            <h1
              onClick={hanldeClickUser}
              className="one-line-ellipsis"
              style={{ margin: 0, fontSize: "0.9rem", fontWeight: "600", cursor: "pointer" }}>
              {comment.user_name}
            </h1>
            <span style={{ fontWeight: "normal", fontSize: "0.7rem", color: colors.grayColor }}>
              {comment.created_at}
            </span>
          </Flex>
        </Flex>

        <span className="two-line-ellipsis" style={{ fontSize: isMobileView ? "0.9rem" : "0.8125rem", margin: 0 }}>
          {comment.comment}
        </span>

        <Flex gap={isMobileView ? 6 : 12} style={{ merginLeft: "-1rem" }}>
          <Button size={btnSize} style={{ border: "none", boxShadow: "none" }} icon={<HeartOutlined />}>
            {comment.likes_count}
          </Button>
          <Button size={btnSize} style={{ border: "none", boxShadow: "none" }} icon={<LikeOutlined />}>
            {comment.likes_count}
          </Button>
        </Flex>
      </Flex>

      {data.perfume && (
        <img
          onClick={hanldeClickPerfume}
          style={{ width: perfumeSize, height: "auto", borderRadius: "50%", cursor: "pointer" }}
          alt=""
          src={data.perfume.image_url}
        />
      )}
    </Flex>
  );
};

export default Comment;
