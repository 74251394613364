import "./index.css";
import { Divider, Flex } from "antd";
import CustomeSvgIcon from "../../../../../components/common/CustomSvgIcon";
import { clockIcon } from "../../../../../utils/svgIcons";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const styleIcon = { fill: "rgb(202, 202, 202)" };

const Notes = ({ notes }) => {
  const { isMobileView } = useWindowSize();

  const imageSize = isMobileView ? 20 : 30;

  return (
    <>
      <Divider style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Notes</Divider>
      <Flex align="center" justify="center">
        <div className="container">
          <CustomeSvgIcon
            style={styleIcon}
            height={"2rem"}
            width={isMobileView ? "2.8rem" : "3.5rem"}
            icon={clockIcon}
          />
          <div className="arrow-line"></div>
        </div>
        <Flex gap={isMobileView ? 9 : 18} wrap justify="center" className="horizontal-arrow-container">
          {notes.map((item) => {
            return (
              <Flex key={item.id} vertical align="center">
                <img
                  style={{ marginBottom: "0.5rem", borderRadius: "50%" }}
                  height={imageSize}
                  width={imageSize}
                  alt={item.name}
                  src={item.image_url}
                />
                <span style={{ fontSize: isMobileView ? "0.8rem" : "0.8125rem" }}>{item.name}</span>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};

export default Notes;
