import "./index.css";
import { useState, useCallback, useEffect, memo } from "react";
import { message, Flex, Button, Empty } from "antd";
import { searchPosts, getFeeds } from "../../../services/supabaseClient";
import { useSearch } from "../../../components/contexts/SearchContext";
import { localStorage_fields, setItemWithExpiry } from "../../../utils/localstorage";
import { TIME_RESET_NEW_FEED } from "../../../utils/service";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PostItem from "./PostItem";
import PostListLoading from "./PostListLoading";
const PAGE_SIZE = 20;

const PostList = memo(() => {
  const { isMobileView } = useWindowSize();

  const { searchValue, showSearchMobile } = useSearch();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [showLoadmore, setShowLoadmore] = useState(false);
  const [loadingMore, setLoadingmore] = useState(false);

  const fetchData = useCallback(async (page, searchValue) => {
    try {
      if (page > 1) setLoadingmore(true);
      else setLoading(true);

      let res = null;
      if (searchValue) {
        res = await searchPosts(searchValue, PAGE_SIZE);
      } else {
        res = await getFeeds(page, PAGE_SIZE);
      }

      if (res.error) {
        message.error(res.error.message);
      } else {
        if (searchValue) {
          const { data } = res;
          if (data && data.length > 0) {
            if (data.length < PAGE_SIZE) setShowLoadmore(false);
            else setShowLoadmore(true);

            setPosts((prev) => {
              let newPosts = data;
              if (page > 1) {
                newPosts = [...prev, ...data];
              }

              setItemWithExpiry(localStorage_fields.searchPostsResults, newPosts, TIME_RESET_NEW_FEED);
              return newPosts;
            });
          } else {
            setPosts([]);
            localStorage.removeItem(localStorage_fields.searchPostsResults);
            setShowLoadmore(false);
          }
        } else {
          const { data, c_post } = res;
          const fetchedData = [];

          if (c_post && c_post.length > 0) {
            c_post.forEach((p) => {
              if (p.post !== null) {
                fetchedData.push({
                  ...p,
                  isCPost: true,
                });
              }
            });
          }

          data.forEach((p) => {
            if (p.post) {
              const index = fetchedData.findIndex((e) => e.id === p.post.id);
              if (index === -1) {
                fetchedData.push(p.post);
              }
            }
          });

          if (fetchedData && fetchedData.length > 0) {
            if (fetchedData.length < PAGE_SIZE) setShowLoadmore(false);
            else setShowLoadmore(true);

            setPosts((prev) => {
              let newPosts = [...fetchedData];
              if (page > 1) {
                newPosts = [...prev, ...fetchedData];
              }

              setItemWithExpiry(localStorage_fields.searchPostsResults, newPosts, TIME_RESET_NEW_FEED);
              return newPosts;
            });
          } else {
            setShowLoadmore(false);
          }
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      if (page > 1) setLoadingmore(false);
      else setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(page, searchValue);
  }, [fetchData, page, searchValue]);

  const handleLoadmore = () => {
    setPage((prev) => prev + 1);
  };

  const itemSpan = isMobileView ? 2 : 5;
  const itemWidth = isMobileView ? "50%" : "20%";
  const postsToShow = [];

  if (posts.length > 0) {
    posts.forEach((e, i) => {
      const index = i % itemSpan;

      if (typeof postsToShow[index] === "object") {
        postsToShow[index].push(e);
      } else {
        postsToShow[index] = [e];
      }
    });
  }

  const gapItem = isMobileView ? 8 : 16;

  return (
    <>
      {loading && <PostListLoading />}

      {!loading && posts.length === 0 && (
        <Empty
          style={{ marginTop: "3rem" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No posts found!</p>
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Flex justify="start" gap={gapItem} style={{ marginTop: showSearchMobile ? "0" : "12px" }}>
          {postsToShow.map((item, i) => {
            return (
              <Flex key={`vfra-posts-to-show-${i}`} vertical style={{ width: itemWidth }} gap={gapItem}>
                {item.map((post) => {
                  return <PostItem key={post.id} post={post} />;
                })}
              </Flex>
            );
          })}
        </Flex>
      )}

      <br></br>

      {!loading && showLoadmore && (
        <Flex justify="center">
          <Button size={isMobileView ? "small" : ""} loading={loadingMore} shape="round" onClick={handleLoadmore}>
            More
          </Button>
        </Flex>
      )}

      <br></br>
    </>
  );
});

export default PostList;
